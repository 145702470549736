<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Using the table of thermodynamic values provided
        <a
          href="https://openstax.org/books/chemistry-2e/pages/g-standard-thermodynamic-properties-for-selected-substances"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >, determine
        <stemble-latex content="$\Delta\text{S}^\circ_{\text{rxn}}$" />
        at
        <stemble-latex content="$298\,\text{K}$" />
        for the conversion of ethylene gas into liquid benzene.
      </p>

      <p class="pl-14 mb-4">
        <chemical-latex content="3 C2H2(g) -> C6H6(l)" />
      </p>

      <calculation-input
        v-model="inputs.deltaS"
        class="mb-5"
        dense
        prepend-text="$\Delta\text{S}^\circ_{\text{rxn}}:$"
        append-text="$\text{J K}^{-1}\text{mol}^{-1}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) Based on your answer for part a) and using the fact that the enthalpy of the reaction,
        <stemble-latex content="$\Delta\text{H}^\circ_{\text{rxn}}=-631.1\,\text{kJ/mol,}$" />
        determine the standard Gibb's Free energy of the reaction.
      </p>

      <calculation-input
        v-model="inputs.freeEnergy"
        class="mb-5"
        dense
        prepend-text="$\Delta\text{G}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-1">
        c) Under standard conditions, in which direction would the reaction be spontaneous at
        <stemble-latex content="$298\,\text{K?}$" />
      </p>

      <v-radio-group v-model="inputs.spontDirection" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question290',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaS: null,
        freeEnergy: null,
        spontDirection: null,
      },
      options: [
        {expression: 'Forward direction', value: 'forward'},
        {expression: 'Reverse direction', value: 'reverse'},
        {expression: 'Neither direction', value: 'neither'},
        {expression: 'Both directions', value: 'both'},
      ],
    };
  },
};
</script>
